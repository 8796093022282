<template>
    <div :class="['block-socials', {'grid-container': isGrid}]" :style="{justifyContent: content.position}">
        <a v-for="(button, n) of content.list"
           :key="n"
           :href="setUrl(button)"
           target="_top"
           class="block-socials__item"
        >
            <div :class="[
                `btn-social--${button.app} btn-social`,
                 [content.displayType]
                ]">
                <div class="btn-social__icon">
                    <v-icon :src="button.icon" />
                </div>
                <span class="btn-social__label" v-if="!content.displayType">{{ button.linkName || button.label }}</span>
            </div>
        </a>
    </div>
</template>

<script>
    import VIcon from "./VIcon";
    export default {
        name: "TapSocial",
        components: {
            VIcon
        },
        props: {
            content: {}
        },
        computed: {
            isGrid() {
                return ['compact', 'circle'].includes(this.content.displayType)
            }
        },
        methods: {
            setUrl(btn) {
                if (btn.social_type === 'social') {
                    // return `https://${btn.appDomain}${btn.link}`
                    return btn.link
                }
                if (btn.social_type === 'msg') {
                    let link = `${btn.appDomain}${btn.link}`
                    if (btn.app === 'wa') {
                        link += `&text=${encodeURIComponent(btn.msgTemplate)}`
                    }
                    return link
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    .block-socials {
        width: 100%;
        &__item {
            display: block;
            margin-bottom: 14px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .grid-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        .block-socials__item {
            margin-bottom: 0;
        }
    }


    .btn-social {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        cursor: pointer;
        padding: 12px 15px;
        width: 100%;
        min-height: auto;
        border-radius: 3px;
        color: #fff;
        font-size: 16px;
        transition: opacity .2s ease;
        &:hover {
            opacity: 0.9;
        }
        &.compact,
        &.circle {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            width: 60px;
            .btn-social__icon {
                width: initial;
            }
        }
        &.circle {
            border-radius: 50%;
        }
        &__icon {
            width: 48px;
            display: flex;
            justify-content: center;
            font-size: 28px;
        }

        &--vk,
        &--vk-msg {
            background-color: #4D76A1;
        }
        &--fb {
            background-color: #3b5999;
        }
        &--youtube {
            background-color: #cc181e;
        }
        &--ig {
            background-color: #f00075;
        }
        &--ok {
            background-color: #f7941d;
        }
        &--tk {
            background-color: #000;
        }
        &--tg {
            background-color: #039BE5;
        }
        &--wa {
            background-color: #55cd6c;
        }
        &--vb {
            background-color: #7d3daf;
        }
        &--msg {
            background-color: #0084ff;
        }
        &--selected {
            background: #cdcecf;
            cursor: default;
            pointer-events: none;
        }

        &__label {
            text-align: center;
            padding-right: 48px;
            white-space: nowrap;
            overflow: hidden;
            /*text-overflow: ellipsis;*/
        }
        @media (min-width: 769px) {
            &__label {
                text-align: center;
            }
        }
    }
</style>
